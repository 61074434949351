import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAnxzQuHb9Q8-sSRQbrqv5Qoow6AJ1Zc60",
  authDomain: "inspect-9b276.firebaseapp.com",
  projectId: "inspect-9b276",
  storageBucket: "inspect-9b276.appspot.com",
  messagingSenderId: "841173326768",
  appId: "1:841173326768:web:216ea61e48fe43c0ced475"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase
