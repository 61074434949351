import Offline from '@/store/Models/Offline'

class LocalUser extends Offline {

  constructor() {
    super('users')
  }

  async signIn (username, password) {
    const db = await this.getDb()
    return new Promise((resolve, reject) => {
      let trans = db.transaction([this.model], 'readonly')
      trans.oncomplete = e => {
        resolve(user)
      }

      let store = trans.objectStore(this.model)
      let user = null

      store.openCursor().onsuccess = e => {
        let cursor = e.target.result
        if (cursor) {
          let check = false
          let query = {
            email: username,
          }
          for (const [key, value] of Object.entries(query)) {
            if(cursor.value[key] === value) {
              check = true
            }
          }
          if(check) {
            user = cursor.value
          }

          cursor.continue()
        }
      }

    })
  }

}

export default LocalUser
