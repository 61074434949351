<template></template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import store from '@/store'

export default {
  name: 'owner-sockets',
  components: {

  },
  data () {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
  },
  created () {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
  },
  sockets () {

  }
}
</script>
