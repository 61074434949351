import Model from '@/store/Models/Model'

class CarGroup extends Model {

  constructor() {
    super('car-groups')
  }

}

export default CarGroup
