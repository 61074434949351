import New from '@/store/Models/New'

class NewClassificator extends New {

  constructor() {
    super('new-classificators')
  }

}

export default NewClassificator
