class Offline {

  constructor(model) {
    this.model = model
    this.DB_NAME = 'new'
    this.DB_VERSION = 1
  }

  async getDb() {
    return new Promise((resolve, reject) => {

      let request = window.indexedDB.open(this.DB_NAME, this.DB_VERSION)

      request.onerror = e => {
        objectStore
        console.log('Error opening db', e)
        reject('Error')
      }

      request.onsuccess = e => {
        resolve(e.target.result)
      }

      request.onupgradeneeded = e => {
        let db = e.target.result
        db.createObjectStore('new-classificators', {
          autoIncrement: true,
          keyPath: 'id'
        })
      }
    })
  }

  async getAll() {
    const db = await this.getDb()
    return new Promise((resolve, reject) => {
      let trans = db.transaction([this.model], 'readonly')
      trans.oncomplete = e => {
        resolve(data)
      }

      let store = trans.objectStore(this.model)
      let data = []

      store.openCursor().onsuccess = e => {
        let cursor = e.target.result
        if (cursor) {
          data.push(cursor.value)
          cursor.continue()
        }
      }

    })
  }

  async addMultipleData(data) {
    const db = await this.getDb()
    return new Promise(((resolve, reject) => {
      let trans = db.transaction([this.model], 'readwrite')
      data.forEach((item) => {
        let store = trans.objectStore(this.model)
        store.put(item)
      })
      trans.oncomplete = e => {
        resolve()
      }
    }))
  }

  async removeAndAdd(data) {
    const db = await this.getDb()
    return new Promise((async (resolve, reject) => {
      let trans = db.transaction([this.model], 'readwrite')
      let store = trans.objectStore(this.model)
      await store.clear()
      data.forEach((item) => {
        let store = trans.objectStore(this.model)
        store.put(item)
      })
      trans.oncomplete = e => {
        resolve()
      }
    }))
  }

  async addData(data) {
    const db = await this.getDb()
    return new Promise((resolve, reject) => {
      let trans = db.transaction([this.model], 'readwrite')
      trans.oncomplete = e => {
        resolve()
      }

      let store = trans.objectStore(this.model)
      store.add(data)
    })
  }

  async editData(data) {
    const db = await this.getDb()
    return new Promise(resolve => {
      let trans = db.transaction([this.model], 'readwrite')
      trans.oncomplete = () => {
        resolve()
      }

      let store = trans.objectStore(this.model)
      store.put(data)

    })
  }

  async delete(id) {
    const db = await this.getDb()
    return new Promise((resolve, reject) => {
      let trans = db.transaction([this.model], 'readwrite')
      trans.oncomplete = e => {
        resolve()
      }

      let store = trans.objectStore(this.model)
      store.delete(id)
    })
  }

  async deleteMany(data) {
    const db = await this.getDb()
    return new Promise((resolve, reject) => {
      let trans = db.transaction([this.model], 'readwrite')
      trans.oncomplete = e => {
        resolve()
      }

      data.forEach((item) => {
        let store = trans.objectStore(this.model)
        store.delete(item.id)
      })

    })
  }

  async get(query) {
    const db = await this.getDb()
    return new Promise((resolve, reject) => {
      let trans = db.transaction([this.model], 'readonly')
      trans.oncomplete = e => {
        resolve(data)
      }

      let store = trans.objectStore(this.model)
      let data = []

      store.openCursor().onsuccess = e => {
        let cursor = e.target.result
        if (cursor) {
          let check = false
          for (const [key, value] of Object.entries(query)) {
            if(cursor.value[key] === value) {
              check = true
            }
          }
          if(check) {
            data.push(cursor.value)
          }

          cursor.continue()
        }
      }

    })
  }

  async clearTable () {
    const db = await this.getDb()
    return new Promise(((resolve, reject) => {
      let trans = db.transaction([this.model], 'readwrite')
      let store = trans.objectStore(this.model)
      store.clear()
    }))
  }

}

export default Offline
