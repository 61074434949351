class AuthService {
  isAuthenticated = () => {
    const accessToken = localStorage.getItem('accessToken')
    return (typeof accessToken !== 'undefined' && accessToken !== null && accessToken !== '')
  }
  user = () => {
    if (this.isAuthenticated()) {
      return JSON.parse(localStorage.getItem('user'))
    }
    return null
  }
  checkPageAccess = (page) => {
    let pages = localStorage.getItem('all_pages')
    if (pages) {
      pages = JSON.parse(pages)
      let index = pages.findIndex((i) => i.route === page.name)
      let localIndex = pages.findIndex((i) => i.url === page.name)
      return (index >= 0 || localIndex >= 0)
    }
    return false
  }
}
export default new AuthService()
