<template></template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import store from '@/store'

export default {
  name: 'admin-sockets',

  components: {

  },

  data () {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
    }
  },

  computed: {
    user () {
      return this.$store.state.user
    },
  },

  created () {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
  },

  mounted () {
    // this.sockets.subscribe('INFORM_ADMIN_ABOUT_API_USE', (count) => {
    //   const element = document.getElementById('group-menu-badge-api')
    //   if (element) {
    //     element.style.display = 'block'
    //     element.innerText = count
    //   }
    // });
  },

  sockets () {

  }
}
</script>
