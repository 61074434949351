export default [
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/views/pages/companies/Companies'),
    meta: {
      auth: true,
      pageTitle: 'Home',
    },
  },
  {
    path: '/view-companies',
    name: 'view-companies',
    component: () => import('@/views/pages/companies/ViewCompanies'),
    meta: {
      auth: true,
      pageTitle: 'Home',
    },
  },
]
