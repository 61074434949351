import Offline from '@/store/Models/Offline'

class LocalCarModel extends Offline {

  constructor() {
    super('car_models')
  }

}

export default LocalCarModel
