import Model from '@/store/Models/Model'

class Car extends Model {

  constructor() {
    super('cars')
  }

}

export default Car
