import Model from '@/store/Models/Model'

class Color extends Model {

  constructor() {
    super('colors')
  }

}

export default Color
