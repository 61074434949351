export default [
  {
    path: '/settings/menus',
    name: 'settings-menus',
    component: () => import('@/views/pages/menus/Menus'),
    meta: {
      auth: true,
      pageTitle: 'Էջեր',
    },
  },
  {
    path: '/stations',
    name: 'stations',
    component: () => import('@/views/pages/menus/Stations'),
    meta: {
      auth: false,
      layout: 'full',
      pageTitle: 'Էջեր',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/pages/menus/Faq'),
    meta: {
      auth: false,
      layout: 'full',
      pageTitle: 'Էջեր',
    },
  },
]
