import Model from '@/store/Models/Model'

class Classificator extends Model {

  constructor() {
    super('classificators')
  }

}

export default Classificator
