<template></template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import store from '@/store'

export default {
  name: 'mechanic-sockets',
  data () {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    ADD_CHECKUP_FOR_MECHANIC () {
      return 'ADD_CHECKUP_FOR_MECHANIC_' + this.user.company
    },
    REMOVE_CHECKUP_FROM_MECHANIC () {
      return 'REMOVE_CHECKUP_FROM_MECHANIC_' + this.user.company
    },
    EDIT_CHECKUP_FOR_MECHANIC () {
      return 'EDIT_CHECKUP_FOR_MECHANIC_' + this.user.company
    },
    CLEAR_MECHANIC_CHECKOUTS () {
      return 'CLEAR_MECHANIC_CHECKOUTS_' + this.user.company
    },
    EDIT_MECHANIC_CHECKUP_ITEM () {
      return 'EDIT_MECHANIC_CHECKUP_ITEM_' + this.user.company
    }
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
  },
  mounted() {
    // this.sockets.subscribe(this.ADD_CHECKUP_FOR_MECHANIC, (data) => {
    //   this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/ADD_NEW_MECHANIC_CHECKUP', data)
    // });
    // this.sockets.subscribe(this.REMOVE_CHECKUP_FROM_MECHANIC, (data) => {
    //   this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/REMOVE_MECHANIC_CHECKUP', data.id)
    //   this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CHANGE_MECHANIC_EDIT_STATUS', false)
    // });
    // this.sockets.subscribe(this.EDIT_CHECKUP_FOR_MECHANIC, (data) => {
    //   this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/EDIT_MECHANIC_CHECKUP', data)
    // });
    // this.sockets.subscribe(this.CLEAR_MECHANIC_CHECKOUTS, (data) => {
    //   this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CHANGE_MECHANIC_EDIT_STATUS', false)
    //   this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLEAR_MECHANIC_CHECKBOXES')
    //   this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getMechanicCheckups')
    // });
    // this.sockets.subscribe(this.EDIT_MECHANIC_CHECKUP_ITEM, (data) => {
    //   this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_MECHANIC_CHECKUP', data)
    // })
  },
  sockets: {
    connect: function () {
      // this.$socket.emit('MECHANIC_CONNECTED', this.user)
    },
  }
}
</script>
