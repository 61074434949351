import axios from '@axios'
import store from './../../store'
import LocalCheckup from '@/store/Models/checkups/LocalCheckup'
import LocalUser from '@/store/Models/users/LocalUser'
import LocalCompany from '@/store/Models/checkups/LocalCompany'
import LocalMechanic from '@/store/Models/users/LocalMechanic'
import firebase from "@core/firebase/firebase";

let todayStart = new Date()
todayStart.setHours(0, 0, 0, 0)

let todayEnd = new Date()
todayEnd.setHours(23, 59, 59, 59)

export default {
  namespaced: true,
  state: {
    selectedCheckup: null,
    gridApi: null,
    gridColumnApi: null,
    temporary_id: null,
    checkups: [],
    dahk: [],
    inspectionStatusCount: 0,
    export_checkups: [],
    mechanic_checkups: [],
    financial_checkups: [],
    count_checkups: [],
    operator_checkups: [],
    fuel_checkups: [],
    owner_type_checkups: [],
    amounts_checkups: [],
    company_doc_checkups: [],
    search_result: [],
    date_search_result: [],
    search_payment_result: [],
    info_data: [],
    report_view_data: [],
    firstCheckupDate: '1991-09-21',
    pagination: {
      current_page: 1,
      total: 0,
      count: 50
    },
    dahkPagination: {
      current_page: 1,
      total: 0,
      count: 50
    },
    filters: {
      Opinion: true,
      startDate: todayStart,
      endDate: todayEnd,
      search: '',
      company: '',
      fields: {},
      type: '',
    },
    syncedEnabled: false,
    dateRange: {
      startDate: todayStart,
      endDate: todayEnd,
    },
    docs: {
      classificator: false,
      financial: false,
      fuel: false,
      count: false,
      info: false,
      individual: false,
      company: false,
      entrepreneur: false,
      amounts: false,
      operators: false,
      reportView: false
    },
    add: false,
    edit: false,
    copy: false,
    copy_payment: false,
    form: {},
    copy_form: {},
    copy_payment_form: {},
    ownerStatuses: [
      {
        id: 0,
        title: 'Ֆիզիկական անձ'
      },
      {
        id: 1,
        title: 'Իրավաբանական անձ'
      },
      {
        id: 2,
        title: 'Անհատ ձեռներեց'
      }
    ],
    opinions: [
      {
        value: true,
        label: 'Սարքին է'
      },
      {
        value: false,
        label: 'Ճանաչվել է անսարք'
      }
    ],
    changes: [
      {
        value: false,
        label: 'Ոչ'
      },
      {
        value: true,
        label: 'Այո'
      }
    ],
    nextCheckups: [
      {
        value: '0',
        label: '6 ամսից'
      },
      {
        value: 1,
        label: '1 տարուց'
      },
      {
        value: 2,
        label: '2 տարուց'
      }
    ],
    mechanic_editing: false,
    company: null,
    askToEditItem: null,
    askToEdit: null,
    company_operators: [],
    checkupNumbers: [],
    checkupHistory: [],
    companyInfo: null,
  },
  getters: {},
  mutations: {

    SET_SYNCED_STATUS(state, value) {
      state.syncedEnabled = value;
    },

    SET_CHECKUP_COMPANY(state, data) {
      state.companyInfo = data
    },

    SET_CHECKUP_NUMBERS (state, data) {
      state.checkupNumbers = data
    },

    SET_CHECKUP_HISTORY (state, data) {
      state.checkupHistory = data
    },

    SET_INITIAL_FILTERS(state, data) {
      Object.assign(state.filters, data)
    },

    SET_GRID_API(state, data) {
      state.gridApi = data
    },

    SET_GRID_COLUMN_API(state, data) {
      state.gridColumnApi = data
    },

    SET_TEMPORARY_ID(state, id) {
      state.temporary_id = id
    },

    SET_CHECKUPS(state, data) {
      state.checkups = data
    },

    SET_DAHKS(state, data) {
      state.dahk = data
    },

    SET_INSPECTION_STATUS_COUNT(state, data) {
      state.inspectionStatusCount = data
    },

    SET_FINANCIAL_CHECKUPS(state, data) {
      state.financial_checkups = data
    },

    SET_COUNT_CHECKUPS (state, data) {
      state.count_checkups = data
    },

    SET_OPERATOR_CHECKUPS (state, data) {
      state.operator_checkups = data
    },

    SET_FUEL_CHECKUPS(state, data) {
      state.fuel_checkups = data
    },

    SET_PAGINATION(state, data) {
      state.pagination = data
    },

    SET_DAHKS_PAGINATION(state, data) {
      state.dahkPagination = data
    },

    ADD_CHECKUP(state, item) {
      if (state.filters.Opinion === item.Opinion) {
        state.checkups.unshift(item)
      }
      // else if (item.new) {
      //   state.checkups.unshift(item)
      // }
    },

    EDIT_CHECKUP(state, item) {
      const index = state.checkups.findIndex((e) => e._id === item._id)
      if (index >= 0) {
        Object.assign(state.checkups[index], item)
      }
    },

    EDIT_CHECKUP_LOCAL(state, item) {
      const index = state.checkups.findIndex((e) => e.id === item.id)
      if (index >= 0) {
        Object.assign(state.checkups[index], item)
      }
    },

    REMOVE_CHECKUP(state, id) {
      const index = state.checkups.findIndex((e) => e._id === id)
      if (index >= 0) {
        state.checkups.splice(index, 1)
      }
    },

    REMOVE_CHECKUP_LOCAL (state, id) {
      const index = state.checkups.findIndex((e) => e.id === id)
      if (index >= 0) {
        state.checkups.splice(index, 1)
      }
    },

    CHANGE_ADD_STATE(state, value) {
      state.add = value
    },

    OPEN_EDIT_FORM(state, data) {
      state.form = JSON.parse(JSON.stringify(data))
      state.edit = true
    },

    SET_COPY_FORM(state, data) {
      state.copy_form = JSON.parse(JSON.stringify(data))
      state.copy = true
    },

    SET_COPY_PAYMENT_FORM(state, data) {
      state.copy_payment_form = JSON.parse(JSON.stringify(data))
      state.copy_payment = true
    },

    CLOSE_EDIT_FORM(state) {
      state.edit = false
    },

    CLOSE_COPY_FORM(state) {
      state.copy = false
    },

    UPDATE_FILTERS(state, filters) {
      Object.assign(state.filters, filters)
    },

    CLEAR_MECHANIC_CHECKBOXES(state) {
      state.mechanic_checkups = []
    },

    SET_MECHANIC_CHECKUPS(state, data) {
      state.mechanic_checkups.push(...data)
    },

    ADD_NEW_MECHANIC_CHECKUP(state, item) {
      state.mechanic_checkups.unshift(item)
    },

    EDIT_MECHANIC_CHECKUP(state, item) {
      const index = state.mechanic_checkups.findIndex((e) => e.id === item.id)
      if (index >= 0) {
        Object.assign(state.mechanic_checkups[index], item)
      }
    },

    UPDATE_MECHANIC_CHECKUP(state, data) {
      const id = (data.temporary_id !== null) ? data.temporary_id : data.item._id
      const index = state.mechanic_checkups.findIndex((e) => e.id === id)
      if (index >= 0) {
        const item = {
          id: data.item._id,
          new: false,
          classificators: data.item.classificators,
          CarRegNo: data.item.CarRegNo,
          createdBy: data.item.createdBy
        }
        Object.assign(state.mechanic_checkups[index], item)
      }
    },

    REMOVE_MECHANIC_CHECKUP(state, id) {
      const index = state.mechanic_checkups.findIndex((e) => e.id === id)
      if (index >= 0) {
        state.mechanic_checkups.splice(index, 1)
      }
    },

    CHANGE_MECHANIC_EDIT_STATUS(state, status) {
      state.mechanic_editing = status
    },

    UPDATE_CHECKUP_CLASSIFICATORS(state, data) {
      const index = state.checkups.findIndex((e) => e._id === data.id)
      if (index >= 0) {
        Object.assign(state.checkups[index].classificators, data.classificators)
      }
    },

    SET_SEARCH_RESULT(state, data) {
      state.search_result = data
    },

    SET_SEARCH_DATE_RESULT(state, data) {
      state.date_search_result = data
    },

    SET_SEARCH_PAYMENT_RESULT(state, data) {
      state.search_payment_result = []
      if (data) {
        state.search_payment_result.push(data)
      }

    },

    OPEN_DOC(state, doc) {
      state.docs = {
        classificator: false,
        financial: false,
        fuel: false,
        count: false,
        info: false,
        individual: false,
        company: false,
        entrepreneur: false,
        amounts: false,
        operators: false,
        reportView: false
      }
      state.docs[doc] = true
    },

    CLOSE_ALL_DOCS(state) {
      state.docs.financial = false
      state.docs.classificator = false
    },

    CLOSE_DOC(state, doc) {
      state.docs[doc] = false
    },

    SET_SELECTED_CHECKUP(state, data) {
      state.selectedCheckup = data
    },

    SET_COMPANY(state, data) {
      state.company = data
    },

    UPDATE_DATE_RANGES(state, data) {
      state.filters.startDate = data.startDate
      state.filters.endDate = data.endDate

      state.dateRange.startDate = data.startDate
      state.dateRange.endDate = data.endDate
    },

    SET_FIRST_CHECKUP_DATE(state, data) {
      state.firstCheckupDate = data
    },

    SET_ASK_TO_EDIT_ITEM(state, data) {
      state.askToEditItem = data
    },

    SET_ASK_TO_EDIT(state, data) {
      state.askToEdit = data
    },

    SET_COMPANY_OPERATORS(state, data) {
      state.company_operators = data
    },

    SET_EXPORT_CHECKUPS(state, data) {
      state.export_checkups = data
    },

    SET_INFO_CHECKUPS (state, data) {
      state.info_data = data
    },

    SET_REPORT_VIEW_CHECKUPS (state, data) {
      state.report_view_data = data
    },

    SET_OWNER_TYPE_CHECKUPS (state, data) {
      state.owner_type_checkups = data
    },

    SET_COMPANY_DOC_CHECKUPS (state, data) {
      state.company_doc_checkups = data
    },

    SET_AMOUNTS_CHECKUPS (state, data) {
      state.amounts_checkups = data
    },

  },
  actions: {

    getFirstCheckup({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('get-first-checkup')
          .then((response) => {
            commit('SET_FIRST_CHECKUP_DATE', response.data.data.createdAt)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getCheckups(ctx, query = '') {
      return (store.state.serverStatus) ? ctx.dispatch('getLiveCheckups', query) : ctx.dispatch('getLocalCheckups', query)
    },

    getAllCheckups(ctx, query) {
      return new Promise(((resolve, reject) => {
        axios.get('tech-service-all-checkups?' + query)
          .then((response) => {
            ctx.commit('SET_CHECKUPS', response.data.data)
            ctx.commit('SET_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    getViewerCheckups(ctx, query) {
      return new Promise(((resolve, reject) => {
        axios.get('tech-service-viewer-checkups?' + query)
          .then((response) => {
            ctx.commit('SET_CHECKUPS', response.data.data)
            ctx.commit('SET_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    getControls(ctx, query) {
      return new Promise(((resolve, reject) => {
        axios.get('controls?' + query)
          .then((response) => {
            ctx.commit('SET_CHECKUPS', response.data.data)
            ctx.commit('SET_DAHKS', response.data.dahk)
            ctx.commit('SET_DAHKS_PAGINATION', response.data.dahkPagination)
            ctx.commit('SET_INSPECTION_STATUS_COUNT', response.data.inspectionStatusCount)
            ctx.commit('SET_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    getAllDahk(ctx, query) {
      return new Promise((resolve, reject) => {
        axios.get('getAllDahk?' + query)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              reject(error)
            })
      })
    },

    getCompanyCheckups({ commit }, query) {
      return new Promise(((resolve, reject) => {
        axios.get('tech-service-company-checkups?' + query)
          .then((response) => {
            commit('SET_CHECKUPS', response.data.data)
            commit('SET_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    getLiveCheckups(ctx, query = '') {
      return new Promise((resolve, reject) => {
        axios.get('tech-service-checkup?' + query)
          .then((response) => {
            ctx.commit('SET_CHECKUPS', response.data.data)
            ctx.commit('SET_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch((error) => {
            if (!error.response) {
              resolve(ctx.dispatch('getLocalCheckups', query))
            } else {
              reject(error)
            }
          })
      })
    },

    getFinancialCheckups({ commit }, query = '') {
      return new Promise((resolve, reject) => {
        axios.get('tech-service-financial-checkup?' + query)
          .then((response) => {
            commit('SET_FINANCIAL_CHECKUPS', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getCountCheckups ({commit}, query = '') {
      return new Promise((resolve, reject) => {
        axios.get(`tech-service-count-checkups?${query}`)
          .then(response => {
            commit('SET_COUNT_CHECKUPS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getOperatorCheckups ({commit}, query = '') {
      return new Promise((resolve, reject) => {
        axios.get(`tech-service-operator-checkups?${query}`)
          .then(response => {
            commit("SET_OPERATOR_CHECKUPS", response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getInfoCheckups ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`tech-service-info-checkups?${payload}`)
          .then(response => {
            commit('SET_INFO_CHECKUPS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getReportViewCheckups ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`tech-service-report-view-checkups?${payload}`)
            .then(response => {
              commit('SET_REPORT_VIEW_CHECKUPS', response.data.data)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
      })
    },

    getCheckupsByOwnerType ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`tech-service-owner-type-checkups?${query}`)
          .then(response => {
            commit('SET_OWNER_TYPE_CHECKUPS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getAmountsCheckups({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`tech-service-amounts-checkups?${query}`)
          .then(response => {
            commit('SET_AMOUNTS_CHECKUPS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCompanyDocCheckups ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`tech-service-company-doc-checkups?${query}`)
          .then(response => {
            commit('SET_COMPANY_DOC_CHECKUPS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getFuelCheckups({ commit }, query = '') {
      return new Promise((resolve, reject) => {
        axios.get('tech-service-fuel-checkup?' + query)
          .then((response) => {
            commit('SET_FUEL_CHECKUPS', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getLocalCheckups(ctx, query = '') {
      return new Promise(async (resolve, reject) => {
        try {
          const model = new LocalCheckup()
          const data = await model.getAll()
          const result = []
          const companyModel = new LocalCompany()
          let companies = await companyModel.getAll()
          let company = companies[0]
          const mechanicModel = new LocalMechanic()
          let mechanics = await mechanicModel.getAll()
          const userModel = new LocalUser()
          let users = await userModel.getAll()
          data.map((e) => {
            let temp = {
              ...e,
              company: company
            }
            let mechanicIndex = mechanics.findIndex((m) => m._id === e.mechanic)
            if (mechanicIndex >= 0) {
              temp.mechanic = mechanics[mechanicIndex]
            }
            else {
              temp.mechanic = mechanics[0]
            }
            let userIndex = users.findIndex((u) => u._id === e.createdBy)
            if (userIndex >= 0) {
              temp.createdBy = users[userIndex]
            }
            else {
              temp.createdBy = users[0]
            }
            result.push(temp)
          })
          ctx.commit('SET_CHECKUPS', result)
          resolve(true)
        } catch (e) {
          reject(e)
        }
      })
    },

    checkBeforeAdd(ctx, payload) {
      return (store.state.serverStatus) ? ctx.dispatch('checkBeforeAddLive', payload) : ctx.dispatch('checkBeforeAddLocal', payload)
    },

    checkBeforeAddLive({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('tech-service-checkup-check-before-add', payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            if (!error.response) {
              resolve(true)
            } else {
              reject(error)
            }
          })
      })
    },

    checkBeforeAddLocal({ commit }, payload) {
      return new Promise((resolve, reject) => {
        resolve(true)
      })
    },

    addCheckup(ctx, payload) {
      return (store.state.serverStatus) ? ctx.dispatch('addCheckupLive', payload) : ctx.dispatch('addCheckupLocal', payload)
    },

    addCheckupLive(ctx, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios.post('tech-service-checkup', payload)
          .then((response) => {
            ctx.commit('ADD_CHECKUP', response.data.item)
            resolve(response)
          })
          .catch((error) => {
            if (!error.response) {
              ctx.dispatch('addCheckupLocal', payload)
            } else {
              reject(error)
            }

          })
      })
    },

    addCheckupRestored(ctx, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios.post('tech-service-checkup', payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    addAttachmentOld(ctx, data) {
      return new Promise((resolve, reject) => {
        if (data.file) {
          const storageRef = firebase.storage().ref();
          const fileName = data.file.name
          const parts = fileName.split('.');
          const format = parts.pop(); // Get the file format
          const timestamp = new Date().getTime(); // Get the current timestamp
          const randomString = Math.random().toString(36).substring(2, 8); // Generate a random string
          const uniqueName = `${timestamp}-${randomString}.${format}`;
          console.log('uniqueName', uniqueName)
          const audioRef = storageRef.child(uniqueName);
          audioRef.put(data.file)
              .then(response => {
                const file = `https://firebasestorage.googleapis.com/v0/b/${response.metadata.bucket}/o/${uniqueName}?alt=media`
                resolve({
                  status: true,
                  file
                })
              })
              .catch(error => {
                reject({
                  status: false,
                  message: 'Սխալ',
                  error
                })
              })
        }
        else {
          reject({
            status: false,
            message: 'Սխալ'
          })
        }
      })
    },

    addAttachment(ctx, data) {
      return new Promise((resolve, reject) => {
        if (data.file) {
          const formData = new FormData();
          formData.append('attachment', data.file);
          axios.post('upload', formData)
              .then(response => {
                resolve({
                  status: true,
                  file: response.data.attachment,
                })
                resolve(response)
              }).catch(error => {
            reject(error)
          })
        } else {
          reject({
            status: false,
            message: 'Սխալ'
          })
        }
        })
    },

    addCheckupLocal(ctx, item) {
      return new Promise(async (resolve, reject) => {
        reject({message: 'Դուք չեք կարող կատարել տեխզննում առանց ինտերների առկայության'})
        // try {
        //   if (store.state.time && typeof store.state.time.diff !== "undefined" && store.state.time.diff) {
        //     if (store.state.time.diff > 0) {
        //       const data = {
        //         response: {
        //           data: {message: 'Գործողությունն արգելված է կապի բացակայության պատճառով։'}
        //         }
        //       }
        //       reject(data)
        //       return false
        //     }
        //   }
        //   item.createdAt = new Date()
        //   item.updatedAt = new Date()
        //   item.createdBy = store.state.user._id
        //   item.DoubleChecked = false
        //   const companyModel = new LocalCompany()
        //   let companies = await companyModel.getAll()
        //   let company = companies[0]
        //   item.company = company._id
        //   const model = new LocalCheckup()
        //   await model.addData(item)
        //   const mechanicModel = new LocalMechanic()
        //   let mechanics = await mechanicModel.getAll()
        //   let mechanic
        //   let mechanicIndex = mechanics.findIndex((m) => m.id === item.mechanic)
        //   if (mechanicIndex >= 0) {
        //     mechanic = mechanics[mechanicIndex]
        //   }
        //   else {
        //     mechanic = mechanics[0]
        //   }
        //   const payload = {
        //     ...item,
        //     company: company,
        //     createdBy: store.state.user,
        //     mechanic: mechanic
        //   }
        //   ctx.commit('ADD_CHECKUP', payload)
        //   let response = {
        //     data: {
        //       item: payload
        //     }
        //   }
        //   resolve(response)
        // } catch (e) {
        //   reject(e)
        // }
      })
    },

    editCheckup (ctx, payload) {
      return (store.state.serverStatus) ? ctx.dispatch('editCheckupLive', payload) : ctx.dispatch('editCheckupLocal', payload)
    },

    editCheckupLive({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.put('tech-service-checkup/' + payload._id, payload)
          .then((response) => {
            commit('EDIT_CHECKUP', response.data.item)
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },

    editCheckupLocal ({commit}, item) {
      return new Promise(async (resolve, reject) => {
        try {
          item.updatedAt = new Date()
          const model = new LocalCheckup()
          await model.editData(item)
          const companyModel = new LocalCompany()
          let companies = await companyModel.getAll()
          let company = companies[0]
          const mechanicModel = new LocalMechanic()
          let mechanics = await mechanicModel.getAll()
          let mechanic
          let mechanicIndex = mechanics.findIndex((m) => m.id === item.mechanic)
          if (mechanicIndex >= 0) {
            mechanic = mechanics[mechanicIndex]
          }
          else {
            mechanic = mechanics[0]
          }
          const payload = {
            ...item,
            company: company,
            createdBy: store.state.user,
            mechanic: mechanic
          }
          commit('EDIT_CHECKUP_LOCAL', payload)
          let response = {
            data: {
              item: payload
            }
          }
          resolve(response)
        }
        catch(error) {
          reject(error)
        }
      })
    },

    editAdminCheckup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.put('tech-service-all-checkup/' + payload._id, payload)
          .then((response) => {
            commit('EDIT_CHECKUP', response.data.item)
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },

    removeCheckup (ctx, item) {
      return (store.state.serverStatus) ? ctx.dispatch('removeCheckupLive', item) : ctx.dispatch('removeCheckupLocal', item.id)
    },

    removeCheckupLive({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('tech-service-checkup/' + item._id, {
          deleted: true
        })
            .then((response) => {
              commit('EDIT_CHECKUP', response.data.item)
              resolve(response)
            })
            .catch((error) => {
              console.log(error)
              reject(error)
            })
      })
    },

    removeCheckupSoft({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete('tech-service-checkup/' + item._id)
            .then((response) => {
              commit('REMOVE_CHECKUP', item._id)
              resolve(response)
            })
            .catch((error) => {
              console.log(error)
              reject(error)
            })
      })
    },

    removeCheckupLocal ({commit}, id) {
      return new Promise(async (resolve, reject) => {
        try {
          const model = new LocalCheckup()
          await model.delete(id)
          commit('REMOVE_CHECKUP_LOCAL', id)
        }
        catch (error) {
          reject(error)
        }
      })
    },

    getMechanicCheckups({ commit }) {
      return new Promise(((resolve, reject) => {
        axios.get('tech-service-mechanic-checkups')
          .then((response) => {
            commit('SET_MECHANIC_CHECKUPS', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    editCheckupMechanic({ commit }, data) {
      return new Promise(((resolve, reject) => {
        axios.put('tech-service-mechanic-checkups/' + data.id, data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    searchCheckups({ commit }, data) {
      return new Promise(((resolve, reject) => {
        axios.get('search-tech-service-checkup?' + data)
          .then((response) => {
            commit('SET_SEARCH_RESULT', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            commit('SET_SEARCH_RESULT', [])
            reject(error)
          })
      }))
    },

    searchViewCheckups({ commit }, data) {
      return new Promise(((resolve, reject) => {
        axios.get('search-tech-view-service-checkup?' + data)
          .then((response) => {
            commit('SET_SEARCH_RESULT', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    searchCheckupsDate({ commit }, data) {
      return new Promise(((resolve, reject) => {
        axios.get('search-tech-service-checkup-date?' + data)
          .then((response) => {
            commit('SET_SEARCH_DATE_RESULT', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    searchPaymentCheckups({ commit }, data) {
      return new Promise(((resolve, reject) => {
        axios.get('search-tech-service-payment-checkup?' + data)
          .then((response) => {
            commit('SET_SEARCH_PAYMENT_RESULT', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    getCompany(ctx) {
      return (store.state.serverStatus) ? ctx.dispatch('getCompanyLive') : ctx.dispatch('getCompanyLocal')
    },

    getCompanyLive(ctx) {
      return new Promise((resolve, reject) => {
        axios.get('tech-service-company')
          .then(async (response) => {
            ctx.commit('SET_COMPANY', response.data.data)
            ctx.rootState.company = response.data.data;
            const model = new LocalCompany()
            await model.addData(response.data.data)
            resolve(response)
          })
          .catch((error) => {
            if (!error.response) {
              resolve(ctx.dispatch('getCompanyLocal'))
            } else {
              reject(error)
            }
          })
      })
    },

    getCompanyLocal({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const model = new LocalCompany()
          const companies = await model.getAll()
          const item = companies[0]
          commit('SET_COMPANY', item)
          const response = {
            data: {
              data: item
            }
          }
          resolve(response)
        } catch (error) {
          reject(error)
        }

      })
    },

    getCompanyMechanic({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get('company-mechanics/' + id)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getCheckupForEdit({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get('get-checkup-for-edit/' + id)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getOperators({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get('tech-service-company-operators')
          .then(async (response) => {
            const model = new LocalUser()
            await model.removeAndAdd(response.data.data)
            commit('SET_COMPANY_OPERATORS', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getCompanyOperators({ commit }, company) {
      return new Promise((resolve, reject) => {
        axios.get(`ts-company-operators?company=${company}`)
          .then(async (response) => {
            commit('SET_COMPANY_OPERATORS', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getBadge ({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('tech-service-opinion-badge')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getTaxCheckups ({}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`get-checkups-for-tax?${payload}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCheckupsForExport ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`get-checkups-for-export?${payload}`, {responseType: 'blob'})
          .then(response => {
          //  commit('SET_EXPORT_CHECKUPS', response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCheckupsForExportAdmin ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`get-checkups-for-export-admin?${payload}`)
          .then(response => {
            commit('SET_EXPORT_CHECKUPS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCheckupNumbers ({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.get(`get-tech-service-checkup-number/${id}`)
          .then(response => {
            commit("SET_CHECKUP_NUMBERS", response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addNewCheckupNumber ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`add-tech-service-checkup-number/${payload.id}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCheckupHistory ({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.get(`checkup-history/${id}`)
          .then(response => {
            commit("SET_CHECKUP_HISTORY", response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }

  },
}
