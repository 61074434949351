import Offline from '@/store/Models/Offline'

class LocalCompany extends Offline {

  constructor() {
    super('company')
  }

}

export default LocalCompany
