import Vue from 'vue'

const checkPermission = (route, permission) => {
  let pages = localStorage.getItem('all_pages')
  if(pages) {
    pages = JSON.parse(pages)
    const pageIndex = pages.findIndex((i) => i.route === route)
    const localIndex = pages.findIndex((l) => l.url === route)
    if (pageIndex >= 0 || localIndex >= 0) {
      let pageId
      if (pageIndex >= 0) {
        pageId = pages[pageIndex].id
      }
      else if (localIndex >= 0) {
        pageId = pages[localIndex]._id
      }
      let permissions = localStorage.getItem('permissions')
      if (permissions) {
        permissions = JSON.parse(permissions)
        if (permissions[pageId]) {
          const actions = permissions[pageId]
          return actions[permission]
        }
      }
    }
  }
  return false

}

Vue.prototype.$checkPermission = checkPermission
