import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '../services/authService'
import store from './../store'

import settings from '@/router/routes/settings'
import companies from '@/router/routes/companies'
import users from '@/router/routes/users'
import tech_service from '@/router/routes/tech_service'
import statistics from '@/router/routes/statistics'
import api from '@/router/routes/api'
import menus from '@/router/routes/menus'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [

    // Default Pages
    {
      path: '/',
      redirect: store.state.homePage
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

    // Other Pages
    ...companies,
    ...users,
    ...settings,
    ...tech_service,
    ...statistics,
    ...api,
    ...menus
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    if (!auth.isAuthenticated()) {
      router.push({
        path: '/login',
        query: { to: to.path }
      })
    }
    else if (!auth.checkPageAccess(to)) {
      router.push({
        path: '/error-404'
      })
    }
  }



  next()
})

export default router
