import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@axios'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import Region from '@/store/Models/locations/Region'
import County from '@/store/Models/locations/County'
import Car from '@/store/Models/cars/Car'
import CarGroup from '@/store/Models/cars/CarGroup'
import CarType from '@/store/Models/cars/CarType'
import Body from '@/store/Models/cars/Body'
import Color from '@/store/Models/cars/Color'
import Fuel from '@/store/Models/cars/Fuel'
import User from '@/store/Models/users/User'
import Classificator from '@/store/Models/cars/Classificator'
import NewClassificator from '@/store/Models/cars/NewClassificator'
import Mechanic from '@/store/Models/users/Mechanic'
import LocalMechanic from '@/store/Models/users/LocalMechanic'
import LocalCarModel from '@/store/Models/cars/LocalCarModel'
import LocalStreet from '@/store/Models/locations/LocalStreet'
import LocalCheckup from '@/store/Models/checkups/LocalCheckup'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
    },
    strict: process.env.DEV,
    state: {
        serverStatus: true,
        paymentStatus: false,
        redirectStatus: false,
        classificatorsStatus: false,
        accessToken: (localStorage.getItem('accessToken')) ? localStorage.getItem('accessToken') : null,
        user_type: (localStorage.getItem('user_type')) ? localStorage.getItem('user_type') : null,
        user: (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null,
        pages: (localStorage.getItem('pages')) ? JSON.parse(localStorage.getItem('pages')) : null,
        permissions: (localStorage.getItem('permissions')) ? JSON.parse(localStorage.getItem('permissions')) : null,
        homePage: (localStorage.getItem('homePage')) ? localStorage.getItem('homePage') : '/login',
        mechanics: [],
        activeMechanic: (localStorage.getItem('activeMechanic')) ? JSON.parse(localStorage.getItem('activeMechanic')) : null,
        tourSteps: [],
        verificationPage: false,
        verificationMessage: '',
        scan_result: null,
        scanError: false,
        notifications: [],
        notification: null,
        time: (localStorage.getItem('time')) ? JSON.parse(localStorage.getItem('time')) : {},
        today_count: (localStorage.getItem('todayCheckups')) ? parseInt(localStorage.getItem('todayCheckups')) : 0,
        today_offline_count: 0,
        techServiceColumns: (localStorage.getItem('techServiceColumns')) ? JSON.parse(localStorage.getItem('techServiceColumns')) : [],
        columnStatuses: (localStorage.getItem('columnStatuses')) ? JSON.parse(localStorage.getItem('columnStatuses')) : [],
        company: null,
    },
    mutations: {

        UPDATE_TECH_SERVICE_COLUMN_STATUS(state, data) {
            let index = state.columnStatuses.findIndex((c) => c.id == data.id)
            if (index >= 0) {
                state.columnStatuses[index].hide = data.value
                localStorage.setItem('columnStatuses', JSON.stringify(state.columnStatuses))
            }
        },

        SET_TECH_SERVICE_COLUMN_STATUSES(state, data) {
            state.columnStatuses = data
            localStorage.setItem('columnStatuses', JSON.stringify(state.columnStatuses))
        },

        SET_TODAY_COUNT(state, value) {
            state.today_count = value
            localStorage.setItem('todayCheckups', state.today_count)
        },

        SET_TODAY_OFFLINE_COUNT(state, value) {
            state.today_offline_count = value
        },

        SET_SERVER_STATUS(state, value) {
            state.serverStatus = value
        },

        SET_PAYMENT_STATUS(state, value) {
          state.paymentStatus = value;
        },

        SET_REDIRECT_STATUS(state, value) {
            state.redirectStatus = value;
        },

        SET_CLASSIFICATORS_VERSION(state, value) {
            state.classificatorsStatus = value
        },

        SET_TIME(state, now) {
            const nowDate = new Date(now)
            const nowLocal = new Date()
            nowLocal.toLocaleString('AM', {timeZone: 'Asia/Yerevan'})

            let difference = nowDate.getMinutes() - nowLocal.getMinutes()
            difference = Math.abs(difference)

            const timeData = {
                live: nowDate,
                local: nowLocal,
                diff: difference
            }

            Object.assign(state.time, timeData)
            localStorage.setItem('time', JSON.stringify(state.time))
        },

        UPDATE_LOCAL_TIME(state) {
            const nowLocal = new Date()
            nowLocal.toLocaleString('AM', {timeZone: 'Asia/Yerevan'})
            const time = {...state.time}
            time.local = nowLocal
            const now = new Date(time.live)
            now.toLocaleString('AM', {timeZone: 'Asia/Yerevan'})
            let difference = now.getHours() - nowLocal.getHours()
            difference = Math.abs(difference)
            time.diff = difference
            Object.assign(state.time, time)
            localStorage.setItem('time', JSON.stringify(state.time))
        },

        SET_ACCESS_TOKEN(state, token) {
            state.accessToken = token
            localStorage.setItem('accessToken', state.accessToken)
        },

        SET_USER_TYPE(state, type) {
            state.user_type = btoa(type.name)
            localStorage.setItem('user_type', state.user_type)
        },

        SET_USER(state, user) {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
        },

        SET_USER_PAGES(state, pages) {
            state.homePage = '/' + pages[0].route
            localStorage.setItem('homePage', state.homePage)
            localStorage.setItem('all_pages', JSON.stringify(pages))
            const data = []
            pages.map((item) => {
                item.children = []
                if (!item.parent) {
                    data.push(item)
                } else {
                    const index = data.findIndex((i) => i.id === item.parent)
                    if (index >= 0) {
                        delete item.children
                        data[index].children.push(item)
                    }
                }
            })
            data.map((item, index) => {
                if (item.children.length === 0) {
                    delete data[index].children
                }
            })
            state.pages = data
            localStorage.setItem('pages', JSON.stringify(state.pages))
        },

        SET_USER_PAGES_LOCAL(state, pages) {
            state.homePage = '/' + pages[0].slug
            localStorage.setItem('homePage', state.homePage)
            localStorage.setItem('all_pages', JSON.stringify(pages))
            const data = []
            pages.map((item) => {
                item.children = []
                if (!item.parent) {
                    data.push(item)
                } else {
                    const index = data.findIndex((i) => i.id === item.parent)
                    if (index >= 0) {
                        delete item.children
                        data[index].children.push(item)
                    }
                }
            })
            data.map((item, index) => {
                if (item.children.length === 0) {
                    delete data[index].children
                }
            })
            state.pages = data
            localStorage.setItem('pages', JSON.stringify(state.pages))
        },

        SET_USER_PERMISSIONS(state, permissions) {
            let data = {}
            permissions.map((item) => {
                data[item.page] = item
            })
            state.permissions = data
            localStorage.setItem('permissions', JSON.stringify(state.permissions))
        },

        SIGN_OUT(state) {
            state.accessToken = null
            state.user_type = null
            state.user = {}
            state.all_pages = []
            state.pages = []
            state.permissions = []
            state.homePage = '/login'
            state.activeMechanic = null
            localStorage.removeItem('accessToken')
            localStorage.removeItem('user_type')
            localStorage.removeItem('user')
            localStorage.removeItem('pages')
            localStorage.removeItem('all_pages')
            localStorage.removeItem('permissions')
            localStorage.removeItem('homePage')
            localStorage.removeItem('activeMechanic')
        },

        SET_MECHANICS(state, data) {
            state.mechanics = data
        },

        SET_ACTIVE_MECHANIC(state, data) {
            state.activeMechanic = data
            localStorage.setItem('activeMechanic', JSON.stringify(state.activeMechanic))
        },

        SET_TOUR_STEPS(state, steps) {
            state.tourSteps = steps
        },

        OPEN_CODE_VERIFICATION(state) {
            state.verificationPage = true
        },

        SET_VERIFICATION_MESSAGE(state, message) {
            state.verificationMessage = message
        },

        CLOSE_CODE_VERIFICATION(state) {
            state.verificationPage = false
        },

        SET_SCAN_RESULT(state, data) {
            state.scanError = false
            state.scan_result = data
        },

        SET_SCAN_ERROR(state) {
            state.scanError = true
        },

        CLEAR_SCAN_RESULT(state) {
            state.scan_result = null
            state.scanError = false
        },

        SET_NOTIFICATIONS(state, data) {
            state.notifications = data
        },

        ADD_NOTIFICATION(state, item) {
            state.notifications.unshift(item)
        },

        REMOVE_NOTIFICATION(state, id) {
            const index = state.notifications.findIndex((i) => i._id === id)

            if (index >= 0) {
                state.notifications.splice(index, 1)
            }
        },

        SET_NOTIFICATION(state, data) {
            state.notification = data
        },

        SET_TECH_SERVICE_COLUMNS(state, data) {
            state.techServiceColumns = data
            localStorage.setItem('techServiceColumns', JSON.stringify(state.techServiceColumns))
        },

        UPDATE_MENU_BADGE(state, data) {
            let menu = {...state.pages[data.index]}
            menu.tag = data.count
            menu.tagVariant = data.variant
            Object.assign(state.pages[data.index], menu)
        },

        UPDATE_MENU_BADGE_COUNT(state, data) {
            const index = state.pages.findIndex(e => e.route === data.route)
            if (index >= 0) {
                let temp = {...state.pages[index]}
                temp.tag = data.count
                Object.assign(state.pages[index], temp)
            }
        }

    },
    actions: {

        makePayment(ctx, data) {
          return new Promise((resolve, reject) => {
              axios.post('makePayment', data)
                  .then(response => {
                      resolve(response)
                  }).catch(error => {
                      reject(error)
              })
          })
        },

        makeCompanyPayment() {
            return new Promise((resolve, reject) => {
                axios.post('makeCompanyPayment')
                    .then(response => {
                        resolve(response)
                    }).catch(error => {
                    reject(error)
                })
            })
        },

        checkArcaPaymentStatus(ctx, orderId) {
          return new Promise((resolve, reject) => {
              axios.post('payments/confirm_arca', {orderId: orderId})
                  .then(response => {
                      resolve(response);
                  })
                  .catch(error => {
                      reject(error);
                  })
          })
        },

        checkArcaPaymentStatusCompany(ctx, orderId) {
          return new Promise((resolve, reject) => {
              axios.post('payments/confirm_arca_company', {orderId: orderId})
                  .then(response => {
                      resolve(response);
                  })
                  .catch(error => {
                      reject(error);
                  })
          })
        },

        getStatuses() {
            return new Promise((resolve, reject) => {
                axios.get('getBlockStatuses')
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },

        updateSetting(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('updateSetting', data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },

        setTourSteps({commit}, steps) {
            return new Promise((resolve) => {
                commit('SET_TOUR_STEPS', steps)
                resolve(steps)
            })
        },

        checkServerStatus(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('checkStatus')
                    .then((response) => {
                        ctx.commit('SET_TIME', response.data.now)
                        ctx.commit('SET_SERVER_STATUS', true)
                        ctx.commit('SET_PAYMENT_STATUS', response.data.payments);
                        ctx.commit('SET_REDIRECT_STATUS', response.data.redirect);
                        ctx.commit('SET_CLASSIFICATORS_VERSION', response.data.classificators)
                        ctx.dispatch('updateLocalData')
                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('SET_SERVER_STATUS', false)
                        ctx.commit('UPDATE_LOCAL_TIME')
                        reject(error)
                    })
            })
        },

        async updateLocalData(ctx) {
            const localCarModel = new LocalCarModel()
            const localStreets = new LocalStreet()
            const localCheckups = new LocalCheckup()
            const models = await localCarModel.getAll()
            for await (let model of models) {
                await axios.post('cars/models', model)
                await localCarModel.delete(model.id)
            }
            const streets = await localStreets.getAll()
            for await (let street of streets) {
                await axios.post('locations/streets', street)
                await localStreets.delete(street.id)
            }
            const checkups = await localCheckups.getAll()
            for await (let checkup of checkups) {
                await axios.post('tech-service-checkup', checkup)
                await localCheckups.delete(checkup.id)
            }
            if (ctx.state.state) {
                ctx.dispatch('getTodayCheckupsCount')
            }
            ctx.dispatch('getTodayOfflineCheckupsCount')
        },

        updateDB(ctx) {
            return new Promise((async (resolve, reject) => {
                const userType = atob(ctx.state.user_type)
                if (userType === 'TECHNICAL_SERVICE_OWNER') {
                    ctx.dispatch('getOperators')
                }
                if (userType === 'ADMIN' || userType === 'TECHNICAL_SERVICE_OWNER' || userType === 'TECHNICAL_SERVICE_OPERATOR' || userType === 'VIEWER') {
                    ctx.dispatch('updateRegions')
                        .then(() => {
                            ctx.dispatch('updateCounties')
                                .then(() => {
                                    ctx.dispatch('updateCars')
                                        .then(() => {
                                            ctx.dispatch('updateCarGroups')
                                                .then(() => {
                                                    ctx.dispatch('updateCarTypes')
                                                        .then(() => {
                                                            ctx.dispatch('updateBodies')
                                                                .then(() => {
                                                                    ctx.dispatch('updateColors')
                                                                        .then(() => {
                                                                            ctx.dispatch('updateFuels')
                                                                                .then(() => {
                                                                                    ctx.dispatch('updateClassificators')
                                                                                        .then(() => {
                                                                                            ctx.dispatch('updateNewClassificators')
                                                                                                .then(() => {
                                                                                                    resolve(true)
                                                                                                })
                                                                                        })

                                                                                })
                                                                        })
                                                                })
                                                        })
                                                })
                                        })
                                })
                        })
                } else if (userType === 'TECHNICAL_SERVICE_MECHANIC') {
                    ctx.dispatch('updateClassificators')
                        .then(() => {
                            ctx.dispatch('updateNewClassificators')
                                .then(() => {
                                    resolve(true)
                                })
                        })
                } else if (userType === 'VIEWER') {
                    ctx.dispatch('updateNewClassificators')
                        .then(() => {
                            resolve(true)
                        })
                } else {
                    resolve(true)
                }

            }))
        },

        getMechanics(ctx) {
            return new Promise((resolve, reject) => {
                const model = new Mechanic()
                const offlineModel = new LocalMechanic()
                axios.get('tech-service-mechanics')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        await offlineModel.removeAndAdd(response.data.data)
                        ctx.commit('SET_MECHANICS', response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.response) {
                            ctx.dispatch('getOfflineMechanics')
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        getOfflineMechanics({commit}) {
            return new Promise(async (resolve, reject) => {
                try {
                    const model = new LocalMechanic()
                    const data = await model.getAll()
                    commit('SET_MECHANICS', data)
                    resolve(data)
                } catch (error) {
                    reject(error)
                }

            })
        },

        clearLocations() {
            return new Promise((async (resolve) => {
                const region = new Region()
                await region.clearTable()
                let county = new County()
                await county.clearTable()
                resolve(true)
            }))
        },

        clearCars() {
            return new Promise((async (resolve) => {
                const car = new Car()
                await car.clearTable()
                const carGroup = new CarGroup()
                await carGroup.clearTable()
                const carType = new CarType()
                await carType.clearTable()
                const bodies = new Body()
                await bodies.clearTable()
                const colors = new Color()
                await colors.clearTable()
                const fuels = new Fuel()
                await fuels.clearTable()
                resolve(true)
            }))
        },

        getOperators() {
            return new Promise((resolve, reject) => {
                const model = new User()
                axios.get('tech-service-users')
                    .then(async (response) => {
                        await model.addMultipleData(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        updateRegions() {
            return new Promise((async (resolve, reject) => {
                const model = new Region()
                axios.get('locations/regions')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                resolve(true)
            }))
        },

        updateCounties() {
            return new Promise((async (resolve, reject) => {
                const model = new County()
                axios.get('locations/counties')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                resolve(true)
            }))
        },

        updateCars() {
            return new Promise((async (resolve, reject) => {
                const model = new Car()
                axios.get('cars/cars')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                resolve(true)
            }))
        },

        updateCarGroups() {
            return new Promise((async (resolve, reject) => {
                const model = new CarGroup()
                axios.get('cars/groups')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                resolve(true)
            }))
        },

        updateCarTypes() {
            return new Promise((async (resolve, reject) => {
                const model = new CarType()
                axios.get('cars/types')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                resolve(true)
            }))
        },

        updateBodies() {
            return new Promise((async (resolve, reject) => {
                const model = new Body()
                axios.get('cars/bodies')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                resolve(true)
            }))
        },

        updateColors() {
            return new Promise((async (resolve, reject) => {
                const model = new Color()
                axios.get('cars/colors')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                resolve(true)
            }))
        },

        updateFuels() {
            return new Promise((async (resolve, reject) => {
                const model = new Fuel()
                axios.get('cars/fuels')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                resolve(true)
            }))
        },

        updateClassificators() {
            return new Promise((async (resolve, reject) => {
                const model = new Classificator()
                axios.get('cars/classificators')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                resolve(true)
            }))
        },

        updateNewClassificators() {
            return new Promise((async (resolve, reject) => {
                const model = new NewClassificator()
                axios.get('cars/new-classificators')
                    .then(async (response) => {
                        await model.removeAndAdd(response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                resolve(true)
            }))
        },

        getCheckupData(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('find-tech-service?data=' + data)
                    .then((response) => {
                        ctx.commit('SET_SCAN_RESULT', response.data.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        ctx.commit('SET_SCAN_ERROR')
                        reject(error)
                    })
            })
        },

        getNotifications(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('notifications')
                    .then((response) => {
                        ctx.commit('SET_NOTIFICATIONS', response.data.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        getEditHistories(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('edit-histories')
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        addNotification(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('notifications', data)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        readNotification(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.put('notifications/' + payload._id, payload)
                    .then((response) => {
                        ctx.commit('REMOVE_NOTIFICATION', payload._id)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        getTodayCheckupsCount({commit}) {
            let todayStart = new Date()
            todayStart.setHours(0, 0, 0, 0)
            let todayEnd = new Date()
            todayEnd.setHours(23, 59, 59, 59)
            const payload = `start=${todayStart}&end=${todayEnd}`
            return new Promise((resolve, reject) => {
                axios.get(`get-today-checkups-count?${payload}`)
                    .then(response => {
                        commit("SET_TODAY_COUNT", response.data.count)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async getTodayOfflineCheckupsCount({commit}) {
            const model = new LocalCheckup()
            let checkups = await model.getAll()
            commit("SET_TODAY_OFFLINE_COUNT", checkups.length)
        },

        setTechServiceColumns({commit}, columns) {
            commit("SET_TECH_SERVICE_COLUMNS", columns)
        },

        checkNewApiLogs() {
            return new Promise((resolve, reject) => {
                axios.get(`api/check-new`)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        checkPhone({commit}, phone) {
            return new Promise((resolve, reject) => {
                axios.post('auth/checkPhone', {phone: phone})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        addVerifiedPhone({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post('auth/addVerifiedPhone', payload)
                    .then(response => {
                        commit('SET_USER', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        getTwoFactorStatus({ commit }, status) {
            return new Promise((resolve, reject) => {
                axios.get('auth/two-factor-status')
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        updateTwoFactorStatus({ commit }, status) {
            return new Promise((resolve, reject) => {
                axios.post('auth/two-factor-status', {status: status})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }

    },
    getters: {
        getForm: (state) => (clear) => (fields) => (data = null) => {
            let form = {}
            for (const [key, value] of Object.entries(fields)) {
                form[key] = value
            }
            if (data !== null) {
                for (const [key, value] of Object.entries(data)) {
                    form[key] = (!clear) ? value : null
                }
            }
            return form;
        }
    }
})
