import Model from '@/store/Models/Model'

class Fuel extends Model {

  constructor() {
    super('fuels')
  }

}

export default Fuel
