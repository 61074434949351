export default [
  {
    path: '/settings/regions',
    name: 'settings-regions',
    component: () => import('@/views/pages/locations/Regions'),
    meta: {
      auth: true,
      pageTitle: 'Մարզեր',
    },
  },
  {
    path: '/settings/counties',
    name: 'settings-counties',
    component: () => import('@/views/pages/locations/Counties'),
    meta: {
      auth: true,
      pageTitle: 'Համայնքներ',
    },
  },
  {
    path: '/settings/streets',
    name: 'settings-streets',
    component: () => import('@/views/pages/locations/Streets'),
    meta: {
      auth: true,
      pageTitle: 'Փողոցներ',
    },
  }
]
