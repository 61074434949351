import locations from '@/router/routes/locations'
import cars from '@/router/routes/cars'

export default [
  ...locations,
  ...cars,
  {
    path: '/settings/faq',
    name: 'settings-faq',
    component: () => import('@/views/pages/faq/Faq'),
    meta: {
      auth: true,
      pageTitle: 'Համայնքներ',
    },
  },
  {
    path: '/settings/vin-notifications',
    name: 'vin-notifications',
    component: () => import('@/views/pages/vin/VIN'),
    meta: {
      auth: true,
      pageTitle: 'VINs',
    },
  },
]
