<template></template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import carsModule from '@/store/cars/cars'
import locations from '@/store/locations/locations'

export default {
  name: 'operator-sockets',
  components: {
    ToastificationContent
  },
  data () {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      CARS_STORE_MODULE_NAME: 'carsModule',
      LOCATIONS_STORE_MODULE_NAME: 'locationsModule',
      showWarning: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    company () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
    },
    MECHANIC_SELECTED_CHECKUP () {
      return 'MECHANIC_SELECTED_CHECKUP_' + this.user.company
    },

    SEND_OPERATOR_EDITED_CLASSIFICATORS () {
      return 'SEND_OPERATOR_EDITED_CLASSIFICATORS_' + this.user.company
    }
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    if (!store.hasModule(this.LOCATIONS_STORE_MODULE_NAME)) store.registerModule(this.LOCATIONS_STORE_MODULE_NAME, locations)

  },
  mounted() {
    // this.sockets.subscribe(this.MECHANIC_SELECTED_CHECKUP, (data) => {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: 'Ծանուցում',
    //       icon: 'BellIcon',
    //       text: '👋 ' + data.user.name + '-ը սկսել է նշումները',
    //       variant: 'success',
    //     },
    //   })
    //   this.$store.commit('SET_ACTIVE_MECHANIC', data.user)
    // });
    //
    // this.sockets.subscribe(this.SEND_OPERATOR_EDITED_CLASSIFICATORS, (data) => {
    //   if (!data.item.new) {
    //     this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_CHECKUP_CLASSIFICATORS', data.item)
    //   }
    // });
    //
    // // Add Car
    // this.sockets.subscribe('ADD_CAR_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getNewCar', data)
    // })
    //
    // // Edit Car
    // this.sockets.subscribe('EDIT_CAR_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/updateCarData', data)
    // })
    //
    // // Remove Car
    // this.sockets.subscribe('REMOVE_CAR_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/removeCarData', data)
    // })
    //
    // // Add Body
    // this.sockets.subscribe('ADD_BODY_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getNewBodyData', data)
    // })
    //
    // // Edit Body
    // this.sockets.subscribe('EDIT_BODY_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/editNewBodyData', data)
    // })
    //
    // // Delete Body
    // this.sockets.subscribe('DELETE_BODY_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/deleteBodyData', data)
    // })
    //
    // // Add Color
    // this.sockets.subscribe('ADD_COLOR_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getNewColorData', data)
    // })
    //
    // // Edit Color
    // this.sockets.subscribe('EDIT_COLOR_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/editNewColorData', data)
    // })
    //
    // // Delete Color
    // this.sockets.subscribe('DELETE_COLOR_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/deleteNewColorData', data)
    // })
    //
    // // Add County
    // this.sockets.subscribe('ADD_COUNTY_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getNewCountyData', data)
    // })
    //
    // // Edit County
    // this.sockets.subscribe('EDIT_COUNTY_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/editNewCountyData', data)
    // })
    //
    // // Delete County
    // this.sockets.subscribe('DELETE_COUNTY_TO_OPERATOR', (data) => {
    //   this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/deleteNewCountyData', data)
    // })
    //
    // setInterval(this.tick, 1000);
  },
  sockets: {
    connect: function () {
      // this.$socket.emit('OPERATOR_CONNECTED', this.user)
    },
    disconnected: function () {
      // this.$socket.emit('OPERATOR_DISCONNECTED', this.user)
    },
  },
  methods: {
    tick() {
      if (this.company && this.company.blocked) {
        let mins = new Date().getMinutes();
        if (mins == "00") {
          if (!this.showWarning) {
            this.showWarning = true
            this.$swal({
              title: this.company.blockComment,
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        }
        if (mins == "05") {
          this.showWarning = false
        }
      }

    }
  }
}
</script>
