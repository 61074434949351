import axios from '@axios'
import Region from '@/store/Models/locations/Region'
import County from '@/store/Models/locations/County'
import sort from '@core/global/sort'
import store from '@/store'
import LocalStreet from '@/store/Models/locations/LocalStreet'
const uniqid = require('uniqid')

export default {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total: 0,
      count: 50
    },
    all_regions: [],
    regions: [],
    all_counties: [],
    counties: [],
    all_streets: [],
    streets: [],
    notifications: [],
  },
  getters: {},
  mutations: {

    SET_PAGINATION (state, data) {
      state.pagination = data
    },

    SET_REGIONS(state, data) {
      state.regions = data
      state.all_regions = data
    },
    ADD_REGION(state, item) {
      state.regions.push(item)
    },
    UPDATE_REGION(state, item) {
      const index = state.regions.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.regions[index], item)
      }
    },
    DELETE_REGION (state, id) {
      const index = state.regions.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.regions.splice(index, 1)
      }
    },
    SET_COUNTIES (state, data) {
      state.counties = data
      state.all_counties = data
    },
    ADD_COUNTY(state, item) {
      state.counties.push(item)
    },
    UPDATE_COUNTY(state, item) {
      const index = state.counties.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.counties[index], item)
      }
    },
    DELETE_COUNTY (state, id) {
      const index = state.counties.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.counties.splice(index, 1)
      }
    },
    SET_STREETS(state, data) {
      state.streets = data
      state.all_streets = data
    },
    ADD_STREET(state, item) {
      state.streets.push(item)
      //state.all_streets.push(item)
    },
    UPDATE_STREET(state, item) {
      const index = state.streets.findIndex((i) => i.id === item._id)
      if (index >= 0) {
        Object.assign(state.streets[index], item)
      }
    },
    DELETE_STREET(state, id) {
      const index = state.streets.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.streets.splice(index, 1)
      }
    },
    FILTER_COUNTIES_BY_REGION_ID (state, regionId) {
      state.counties = state.all_counties
      state.counties = state.counties.filter(function (el) {
        return el.region === regionId
      });
    },
    FILTER_STREETS_BY_COUNTY_ID (state, countyId) {
      state.streets = state.all_streets
      state.streets = state.streets.filter(function (el) {
        return el.county === countyId
      });
    },
    SET_NOTIFICATIONS(state, data) {
      state.notifications = data
    }
  },
  actions: {

    async getRegions({ commit }) {
      const model = new Region()
      const data = await model.getAll()
      data.sort(sort.dynamicSort("ordering"));
      commit('SET_REGIONS', data)
    },

    addRegion({ commit }, data) {
      return new Promise(((resolve, reject) => {
        const model = new Region()
        axios.post('locations/regions', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              status: response.data.item.status,
              latitude: response.data.item.latitude,
              longitude: response.data.item.longitude
            }
            await model.addData(item)
            commit('ADD_REGION', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    editRegion({ commit }, data) {
      const model = new Region()
      return new Promise(((resolve, reject) => {
        axios.put('locations/regions/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
              status: response.data.item.status,
              latitude: response.data.item.latitude,
              longitude: response.data.item.longitude
            }
            await model.editData(item)
            commit('UPDATE_REGION', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    deleteRegion({ commit }, id) {
      return new Promise((resolve, reject) => {
        const model = new Region()
        const countyModel = new County()
        axios.delete('locations/regions/' + id)
          .then(async (response) => {
            await model.delete(id)
            commit('DELETE_REGION', id)
            const counties = await countyModel.get({region: id})
            if (counties.length > 0) {
              await countyModel.deleteMany(counties)
            }
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async getCounties({ commit }) {
      const model = new County()
      let data
      data = await model.getAll()
      commit('SET_COUNTIES', data)
    },

    async filterCounties ({commit}, filters) {
      const model = new County()
      let data
      data = await model.getAll()
      let result = [...data]
      for await (const [key, value] of Object.entries(filters)) {
        if (value !== '' && value !== null) {
          result = data.filter(function(el) {
            if (value.match(/^[0-9a-fA-F]{24}$/)) {
              return el[key] === value
            }
            else {
              return el[key].toLocaleLowerCase().includes(value.toLocaleLowerCase())
            }
          });
        }
      }
      commit('SET_COUNTIES', result)
    },

    addCounty({ commit }, data) {
      return new Promise(((resolve, reject) => {
        const model = new County()
        axios.post('locations/counties', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              region: response.data.item.region,
              status: response.data.item.status,
              latitude: response.data.item.latitude,
              longitude: response.data.item.longitude
            }
            await model.addData(item)
            commit('ADD_COUNTY', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    async getNewCountyData ({commit}, data) {
      const model = new County()
      const item = {
        id: data._id,
        name: data.name,
        region: data.region,
        status: data.status,
        latitude: response.data.item.latitude,
        longitude: response.data.item.longitude
      }
      await model.addData(item)
      commit('ADD_COUNTY', item)
    },

    editCounty({ commit }, data) {
      const model = new County()
      return new Promise(((resolve, reject) => {
        axios.put('locations/counties/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
              region: response.data.item.region,
              status: response.data.item.status,
              latitude: response.data.item.latitude,
              longitude: response.data.item.longitude
            }
            await model.editData(item)
            commit('UPDATE_COUNTY', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    async editNewCountyData ({commit}, data) {
      const model = new County()
      let item = {
        id: data._id,
        name: data.name,
        region: data.region,
        status: data.status,
        latitude: response.data.item.latitude,
        longitude: response.data.item.longitude
      }
      await model.editData(item)
      commit('UPDATE_COUNTY', item)
    },

    deleteCounty({ commit }, id) {

      return new Promise((resolve, reject) => {
        const model = new County()
        axios.delete('locations/counties/' + id)
          .then(async (response) => {
            await model.delete(id)
            commit('DELETE_COUNTY', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async deleteNewCountyData ({commit}, id) {
      const model = new County()
      await model.delete(id)
      commit('DELETE_COUNTY', id)
    },

    async getStreets({ commit }, query) {
      return new Promise((resolve, reject) => {
        let url = 'locations/streets'
        if (query) {
          url += '?' + query
        }
        axios.get(url)
          .then((response) => {
            commit('SET_STREETS', response.data.data)
            commit('SET_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async getNotifications({ commit }, query) {
      return new Promise((resolve, reject) => {
        let url = 'locations/notifications'
        if (query) {
          url += '?' + query
        }
        axios.get(url)
          .then((response) => {
            commit('SET_NOTIFICATIONS', response.data.data)
            commit('SET_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    addStreetForCheckup (ctx, data) {
      return (store.state.serverStatus) ? ctx.dispatch('addStreet', data) : ctx.dispatch('addStreetLocal', data)
    },

    addStreet({ ctx }, data) {
      return new Promise(((resolve, reject) => {
        axios.post('locations/streets', data)
          .then((response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              county: response.data.item.county,
              status: response.data.item.status
            }
            ctx.commit('ADD_STREET', item)
            resolve(response)
          })
          .catch((error) => {
            if (!error.response) {
              ctx.commit('SET_SERVER_STATUS', false, {root: true})
              ctx.dispatch('addStreetLocal', data)
                .then(() => {
                  resolve(true)
                })
            } else {
              reject(error)
            }

          })
      }))
    },

    addStreetLocal ({commit}, data) {
      return new Promise(async(resolve, reject) => {
        try {
          data.id = uniqid()
          const model = new LocalStreet()
          await model.addData(data)
          const response = {
            data: {
              item: data
            }
          }
          resolve(response)
        }
        catch (error) {
          reject(error)
        }
      })
    },

    editStreet({ commit }, data) {
      return new Promise(((resolve, reject) => {
        axios.put('locations/streets/' + data.id, data)
          .then(async (response) => {
            commit('UPDATE_STREET', response.data.item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    deleteStreet({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete('locations/streets/' + id)
          .then(async (response) => {
            commit('DELETE_STREET', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
