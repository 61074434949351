export default [
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('@/views/pages/statistics/All'),
    meta: {
      auth: true,
      pageTitle: 'Վիճակագրություն',
    },
  },
  {
    path: '/all-statistics',
    name: 'all-statistics',
    component: () => import('@/views/pages/statistics/All'),
    meta: {
      auth: true,
      pageTitle: 'Վիճակագրություն',
    },
  },
  {
    path: '/companies-statistics',
    name: 'companies-statistics',
    component: () => import('@/views/pages/statistics/All'),
    meta: {
      auth: true,
      pageTitle: 'Վիճակագրություն',
    },
  },
  {
    path: '/company-statistics',
    name: 'company-statistics',
    component: () => import('@/views/pages/statistics/All'),
    meta: {
      auth: true,
      pageTitle: 'Վիճակագրություն',
    },
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import('@/views/pages/analysis/Admin'),
    meta: {
      auth: true,
      pageTitle: 'Վերլուծություն',
    },
  },
  {
    path: '/analysis-view',
    name: 'analysis-view',
    component: () => import('@/views/pages/analysis/View'),
    meta: {
      auth: true,
      pageTitle: 'Վերլուծություն',
    },
  },
]
