export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/pages/users/Users'),
    meta: {
      auth: true,
      pageTitle: 'Home',
    },
  },
  {
    path: '/tech-service-owner-users',
    name: 'tech-service-owner-users',
    component: () => import('@/views/pages/users/TechServiceOwnerUsers'),
    meta: {
      auth: true,
      pageTitle: 'Home',
    },
  },
  {
    path: '/user-history',
    name: 'user-history',
    component: () => import('@/views/pages/users/History'),
    meta: {
      auth: true,
      pageTitle: 'Մուտքերի պատմություն',
    },
  },
  {
    path: '/owner-user-history',
    name: 'owner-user-history',
    component: () => import('@/views/pages/users/OwnerHistory'),
    meta: {
      auth: true,
      pageTitle: 'Մուտքերի պատմություն',
    },
  },
]
