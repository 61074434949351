export default [
  {
    path: '/api/users',
    name: 'api-users',
    component: () => import('@/views/pages/api/users/Users'),
    meta: {
      auth: true,
      pageTitle: 'API',
    },
  },
  {
    path: '/api/history',
    name: 'api-history',
    component: () => import('@/views/pages/api/history/History'),
    meta: {
      auth: true,
      pageTitle: 'API',
    },
  },
  {
    path: '/api/notifications',
    name: 'api-notifications',
    component: () => import('@/views/pages/api/history/Notificatiions'),
    meta: {
      auth: true,
      pageTitle: 'API',
    },
  },
  {
    path: '/api/payments',
    name: 'api-payments',
    component: () => import('@/views/pages/api/history/Payments'),
    meta: {
      auth: true,
      pageTitle: 'API',
    },
  },
  {
    path: '/accesses',
    name: 'accesses',
    component: () => import('@/views/pages/api/users/Access'),
    meta: {
      auth: true,
      pageTitle: 'API',
    }
  },
  {
    path: '/accesses_all',
    name: 'accesses_all',
    component: () => import('@/views/pages/api/users/Access'),
    meta: {
      auth: true,
      pageTitle: 'API',
    }
  },
  {
    path: '/api/search-histories',
    name: 'search-histories',
    component: () => import('@/views/pages/api/history/SearchHistory'),
    meta: {
      auth: true,
      pageTitle: 'API',
    },
  },
  {
    path: '/api/view-histories',
    name: 'view-histories',
    component: () => import('@/views/pages/api/history/ViewHistory'),
    meta: {
      auth: true,
      pageTitle: 'API',
    },
  },
  {
    path: '/idram/success',
    redirect: {
      name: 'login',
      query: {
        idram: 'success'
      }
    }
  },
  {
    path: '/idram/fail',
    redirect: {
      name: 'login',
      query: {
        idram: 'fail'
      }
    }
  },
]
