import Offline from '@/store/Models/Offline'

class LocalMechanic extends Offline {

  constructor() {
    super('mechanics')
  }

}

export default LocalMechanic
