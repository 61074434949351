//  © Բիգտեխլայն ՍՊԸ. Բոլոր իրավունքները պաշտպանված են

import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
Vue.use(Vuesax)

// Maz Component
import { MazInput, MazSelect, MazPhoneNumberInput } from 'maz-ui'
Vue.use(MazInput)
Vue.use(MazSelect)
Vue.use(MazPhoneNumberInput)
import 'maz-ui/lib/css/maz-phone-number-input.css'
import 'maz-ui/lib/css/base.css'

import router from './router'
import store from './store'
import App from './App.vue'

import VueAnalytics from 'vue-analytics';
Vue.use(VueAnalytics, {
  id: 'UA-41130371-5',
  router
})


// // Sockets
// const socket_url = process.env.VUE_APP_SOCKET_URL
// import * as io from 'socket.io-client'
// import VueSocketIO from 'vue-socket.io'
// const SocketInstance = io.connect(socket_url, {
//   query: {
//     token: localStorage.getItem('accessToken')
//   }
// });
//
//
// Vue.use(new VueSocketIO({
//   debug: (process.env.NODE_ENV !== 'production'),
//   connection: SocketInstance,
//   vuex: {
//     store,
//     actionPrefix: 'SOCKET_',
//     mutationPrefix: 'SOCKET_'
//   },
// }));

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import './registerServiceWorker'
import '@/libs/sweet-alerts'
import '@/libs/tour'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

import './@core/global/methods'

// Filters
import './@core/filters/filters'

// Composition API
Vue.use(VueCompositionAPI)

// Mask
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
import './@core/icons/fa'

import excel from 'vue-excel-export'
Vue.use(excel)

// Device detector
import * as device from 'mobile-device-detect'
Vue.prototype.$device = device

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
