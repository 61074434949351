import Model from '@/store/Models/Model'

class User extends Model {

  constructor() {
    super('mechanics')
  }

}

export default User
