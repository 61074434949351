import Offline from '@/store/Models/Offline'

class LocalCheckup extends Offline {

  constructor() {
    super('checkups')
  }

}

export default LocalCheckup
