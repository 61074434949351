<template>
  <div>
    <Admin v-if="userType === 'ADMIN'" />
    <Owner v-if="userType === 'TECHNICAL_SERVICE_OWNER'" />
    <Operator v-if="userType === 'TECHNICAL_SERVICE_OPERATOR'" />
    <Mechanic v-if="userType === 'TECHNICAL_SERVICE_MECHANIC'" />
  </div>
</template>

<script>

import Mechanic from '@/sockets/Mechanic'
import Operator from '@/sockets/Operator'
import Admin from '@/sockets/Admin'
import Owner from '@/sockets/Owner'
import store from '@/store'
import carsModule from '@/store/cars/cars'

export default {
  name: 'app-sockets',
  components: {
    Admin,
    Owner,
    Mechanic,
    Operator
  },
  data() {
    return {
      CARS_STORE_MODULE_NAME: 'carsModule',
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    userType () {
      return atob(this.$store.state.user_type)
    },
    GET_NOTIFICATION () {
      return (this.user) ? 'GET_NOTIFICATION_' + this.user.id : ''
    }
  },
  created () {
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
  },
  mounted() {
    this.sockets.subscribe(this.GET_NOTIFICATION, (data) => {
      this.$store.commit('ADD_NOTIFICATION', data)
      const audioFile = require('@/assets/audio/notification.mp3')
      const audio = new Audio(audioFile)
      audio.play()
      this.$store.commit('SET_NOTIFICATION', data)
      this.$bvModal.show('notification-modal')
    })

    // Add Car
    this.sockets.subscribe('ADD_CLASSIFICATOR_TO_USER', (data) => {
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getNewClassificator', data)
    })

    // Edit Car
    this.sockets.subscribe('EDIT_CLASSIFICATOR_TO_USER', (data) => {
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/updateClassificatorData', data)
    })

  },
  sockets:{

  },
}
</script>
