import Model from '@/store/Models/Model'

class CarType extends Model {

  constructor() {
    super('car-types')
  }

}

export default CarType
