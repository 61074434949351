import Model from '@/store/Models/Model'

class County extends Model {

  constructor() {
    super('counties')
  }

}

export default County
