export default [
  {
    path: '/tech-service-all',
    name: 'tech-service-all',
    component: () => import('@/views/pages/tech-service/All'),
    meta: {
      auth: true,
      pageTitle: 'Տեխ․ սպասարկում',
    },
  },
  {
    path: '/tech-service-view',
    name: 'tech-service-view',
    component: () => import('@/views/pages/tech-service/View'),
    meta: {
      auth: true,
      pageTitle: 'Տեխ․ սպասարկում',
    },
  },
  {
    path: '/tech-service-company-view',
    name: 'tech-service-company-view',
    component: () => import('@/views/pages/tech-service/ViewCompany'),
    meta: {
      auth: true,
      pageTitle: 'Տեխ․ սպասարկում',
    },
  },
  {
    path: '/company-tech-service',
    name: 'company-tech-service',
    component: () => import('@/views/pages/tech-service/Company'),
    meta: {
      auth: true,
      pageTitle: 'Տեխ․ սպասարկում',
    },
  },
  {
    path: '/tech-service',
    name: 'tech-service',
    component: () => import('@/views/pages/tech-service/TechService'),
    meta: {
      auth: true,
      pageTitle: 'Տեխ․ սպասարկում',
    },
  },
  {
    path: '/tech-service-mechanic',
    name: 'tech-service-mechanic',
    component: () => import('@/views/pages/tech-service/TechServiceMechanic'),
    meta: {
      auth: true,
      pageTitle: 'Տեխ․ սպասարկում',
    },
  },
  {
    path: '/control',
    name: 'control',
    component: () => import('@/views/pages/control/Control'),
    meta: {
      auth: true,
      pageTitle: 'Հսկողություն',
    },
  },
  {
    path: '/control-view',
    name: 'control-view',
    component: () => import('@/views/pages/control/Control'),
    meta: {
      auth: true,
      pageTitle: 'Հսկողություն',
    },
  },
  {
    path: '/control-company',
    name: 'control-company',
    component: () => import('@/views/pages/control/Control'),
    meta: {
      auth: true,
      pageTitle: 'Հսկողություն',
    },
  },
  {
    path: '/control-ts',
    name: 'control-ts',
    component: () => import('@/views/pages/control/Control'),
    meta: {
      auth: true,
      pageTitle: 'Հսկողություն',
    },
  },
  {
    path: '/co-payments',
    name: 'co-payments',
    component: () => import('@/views/pages/tech-service/TsCo'),
    meta: {
      auth: true,
      pageTitle: 'Բնապ. վճարներ',
    },
  },
  {
    path: '/station-payments',
    name: 'station-payments',
    component: () => import('@/views/pages/companies/Payments'),
    meta: {
      auth: true,
      pageTitle: 'Վճարներ',
    },
  },
  {
    path: '/station-idram-payments',
    name: 'station-idram-payments',
    component: () => import('@/views/pages/companies/IdramPayments'),
    meta: {
      auth: true,
      pageTitle: 'Վճարներ',
    },
  },
  {
    path: '/station-telcell-payments',
    name: 'station-telcell-payments',
    component: () => import('@/views/pages/companies/TelcellPayments'),
    meta: {
      auth: true,
      pageTitle: 'Վճարներ',
    },
  },
  {
    path: '/onepay-payments',
    name: 'onepay-payments',
    component: () => import('@/views/pages/companies/Payments'),
    meta: {
      auth: true,
      pageTitle: 'Վճարներ',
    },
  },
  {
    path: '/onepay-idram-payments',
    name: 'onepay-idram-payments',
    component: () => import('@/views/pages/companies/IdramPayments'),
    meta: {
      auth: true,
      pageTitle: 'Վճարներ',
    },
  },
  {
    path: '/onepay-telcell-payments',
    name: 'onepay-telcell-payments',
    component: () => import('@/views/pages/companies/TelcellPayments'),
    meta: {
      auth: true,
      pageTitle: 'Վճարներ',
    },
  },
  {
    path: '/all_notifications',
    name: 'all_notifications',
    component: () => import('@/views/pages/Notifications/Notifications'),
    meta: {
      auth: true,
      pageTitle: 'Վճարներ',
    },
  },
]
