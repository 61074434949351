import axios from '@axios'
import Car from '@/store/Models/cars/Car'
import CarGroup from '@/store/Models/cars/CarGroup'
import CarType from '@/store/Models/cars/CarType'
import Body from '@/store/Models/cars/Body'
import Color from '@/store/Models/cars/Color'
import Fuel from '@/store/Models/cars/Fuel'
import Classificator from '@/store/Models/cars/Classificator'
import NewClassificator from '@/store/Models/cars/NewClassificator'
import sort from '@core/global/sort'
import store from '@/store'
import LocalCarModel from '@/store/Models/cars/LocalCarModel'
const uniqid = require('uniqid')

export default {
  namespaced: true,
  state: {
    cars: [],
    car_models: [],
    car_groups: [],
    car_types: [],
    bodies: [],
    colors: [],
    fuels: [],
    classificators: [],
    new_classificators: [],
    source_documents: [],
    pagination: {
      current_page: 1,
      total: 0,
      count: 50
    },
  },
  getters: {},
  mutations: {

    SET_PAGINATION (state, data) {
      state.pagination = data
    },

    SET_CARS(state, data) {
      state.cars = data
    },
    ADD_CAR(state, item) {
      state.cars.push(item)
    },
    UPDATE_CAR(state, item) {
      const index = state.cars.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.cars[index], item)
      }
    },
    DELETE_CAR(state, id) {
      const index = state.cars.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.cars.splice(index, 1)
      }
    },
    SET_MODELS(state, data) {
      state.car_models = data
    },
    ADD_MODEL(state, item) {
      state.car_models.push(item)
    },
    UPDATE_MODEL(state, item) {
      const index = state.car_models.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.car_models[index], item)
      }
    },
    DELETE_MODEL(state, id) {
      const index = state.car_models.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.car_models.splice(index, 1)
      }
    },
    SET_GROUPS(state, data) {
      state.car_groups = data
    },
    ADD_GROUP(state, item) {
      state.car_groups.push(item)
    },
    UPDATE_GROUP(state, item) {
      const index = state.car_groups.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.car_groups[index], item)
      }
    },
    DELETE_GROUP(state, id) {
      const index = state.car_groups.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.car_groups.splice(index, 1)
      }
    },
    SET_TYPES(state, data) {
      state.car_types = data
    },
    ADD_TYPE(state, item) {
      state.car_types.push(item)
    },
    UPDATE_TYPE(state, item) {
      const index = state.car_types.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.car_types[index], item)
      }
    },
    DELETE_TYPE(state, id) {
      const index = state.car_types.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.car_types.splice(index, 1)
      }
    },
    SET_BODIES(state, data) {
      state.bodies = data
    },
    ADD_BODY(state, item) {
      state.bodies.push(item)
    },
    UPDATE_BODY(state, item) {
      const index = state.bodies.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.bodies[index], item)
      }
    },
    DELETE_BODY(state, id) {
      const index = state.bodies.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.bodies.splice(index, 1)
      }
    },
    SET_COLORS(state, data) {
      state.colors = data
    },
    ADD_COLOR(state, item) {
      state.colors.push(item)
    },
    UPDATE_COLOR(state, item) {
      const index = state.colors.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.colors[index], item)
      }
    },
    DELETE_COLOR(state, id) {
      const index = state.colors.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.colors.splice(index, 1)
      }
    },
    SET_FUELS(state, data) {
      state.fuels = data
    },
    ADD_FUEL(state, item) {
      state.fuels.push(item)
    },
    UPDATE_FUEL(state, item) {
      const index = state.fuels.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.fuels[index], item)
      }
    },
    DELETE_FUEL(state, id) {
      const index = state.fuels.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.fuels.splice(index, 1)
      }
    },
    SET_CLASSIFICATORS(state, data) {
      state.classificators = data
    },
    SET_NEW_CLASSIFICATORS(state, data) {
      state.new_classificators = data
    },
    ADD_CLASSIFICATOR(state, item) {
      state.classificators.push(item)
    },
    ADD_NEW_CLASSIFICATOR(state, item) {
      state.new_classificators.push(item)
    },
    UPDATE_CLASSIFICATOR(state, item) {
      const index = state.classificators.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.classificators[index], item)
      }
    },
    UPDATE_NEW_CLASSIFICATOR(state, item) {
      const index = state.new_classificators.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.new_classificators[index], item)
      }
    },
    DELETE_CLASSIFICATOR(state, id) {
      const index = state.classificators.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.classificators.splice(index, 1)
      }
    },
    DELETE_NEW_CLASSIFICATOR(state, id) {
      const index = state.new_classificators.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.new_classificators.splice(index, 1)
      }
    },
  },
  actions: {

    async getCars({ commit }, search = null) {
      const model = new Car()
      let data
      if (search) {
        data = await model.like({name: search})
      }
      else {
        data = await model.getAll()
      }
      data.sort(sort.dynamicSort("name"));
      data.sort(sort.dynamicSort("-primary"));
      commit('SET_CARS', data)
    },

    addCar({ commit }, data) {
      return new Promise(((resolve, reject) => {
        const model = new Car()
        axios.post('cars/cars', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              primary: response.data.item.primary
            }
            await model.addData(item)
            commit('ADD_CAR', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    async getNewCar ({commit}, data) {
      const model = new Car()
      const item = {
        id: data._id,
        name: data.name,
        primary: data.primary
      }
      await model.addData(item)
      commit('ADD_CAR', item)
    },

    editCar({ commit }, data) {
      const model = new Car()
      return new Promise(((resolve, reject) => {
        axios.put('cars/cars/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
              primary: response.data.item.primary
            }
            await model.editData(item)
            commit('UPDATE_CAR', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    async updateCarData ({commit}, data) {
      const model = new Car()
      let item = {
        id: data._id,
        name: data.name,
        primary: data.primary
      }
      await model.editData(item)
      commit('UPDATE_CAR', item)
    },

    deleteCar({ commit }, id) {
      return new Promise((resolve, reject) => {
        const model = new Car()
        axios.delete('cars/cars/' + id)
          .then(async (response) => {
            await model.delete(id)
            commit('DELETE_CAR', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async removeCarData ({commit}, id) {
      const model = new Car()
      await model.delete(id)
      commit('DELETE_CAR', id)
    },

    async getModels({ commit }, query) {
      return new Promise((resolve, reject) => {
        let url = 'cars/models'
        if (query) {
          url += '?' + query
        }
        axios.get(url)
          .then((response) => {
            commit('SET_MODELS', response.data.data)
            commit('SET_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    addModelForCheckup (ctx, data) {
      return (store.state.serverStatus) ? ctx.dispatch('addModel', data) : ctx.dispatch('addModelLocal', data)
    },

    addModel(ctx, data) {
      return new Promise(((resolve, reject) => {
        axios.post('cars/models', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              car: response.data.item.car
            }
            ctx.commit('ADD_MODEL', item)
            resolve(response)
          })
          .catch((error) => {
            if (!error.response) {
              ctx.commit('SET_SERVER_STATUS', false, {root: true})
              ctx.dispatch('addModelLocal', data)
                .then(() => {
                  resolve(true)
                })
            } else {
              reject(error)
            }
          })
      }))
    },

    async addModelLocal ({commit}, data) {
      return new Promise(async(resolve, reject) => {
        try {
          data.id = uniqid()
          const model = new LocalCarModel()
          await model.addData(data)
          const response = {
            data: {
              item: data
            }
          }
          resolve(response)
        }
        catch (error) {
          reject(error)
        }
      })
    },

    editModel({ commit }, data) {
      return new Promise(((resolve, reject) => {
        axios.put('cars/models/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
              car: response.data.item.car
            }
            commit('UPDATE_MODEL', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    deleteModel({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete('cars/models/' + id)
          .then(async (response) => {
            commit('DELETE_MODEL', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async getGroups({ commit }) {
      const model = new CarGroup()
      const data = await model.getAll()
      data.sort(sort.dynamicSort("ordering"));
      commit('SET_GROUPS', data)
    },

    addGroup({ commit }, data) {
      return new Promise(((resolve, reject) => {
        const model = new CarGroup()
        axios.post('cars/groups', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name
            }
            await model.addData(item)
            commit('ADD_GROUP', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    editGroup({ commit }, data) {
      const model = new CarGroup()
      return new Promise(((resolve, reject) => {
        axios.put('cars/groups/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
            }
            await model.editData(item)
            commit('UPDATE_GROUP', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    deleteGroup({ commit }, id) {
      return new Promise((resolve, reject) => {
        const model = new CarGroup()
        const carType = new CarType()
        axios.delete('cars/groups/' + id)
          .then(async (response) => {
            await model.delete(id)
            commit('DELETE_GROUP', id)
            const types = await carType.get({group: id})
            if (types.length > 0) {
              await carType.deleteMany(types)
            }
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async getTypes({ commit }) {
      const model = new CarType()
      const data = await model.getAll()
      data.sort(sort.dynamicSort("ordering"));
      commit('SET_TYPES', data)
    },

    addType({ commit }, data) {
      return new Promise(((resolve, reject) => {
        const model = new CarType()
        axios.post('cars/types', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              code: response.data.item.code,
              amount: response.data.item.amount,
              ceo: response.data.item.ceo,
              group: response.data.item.group
            }
            await model.addData(item)
            commit('ADD_TYPE', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    editType({ commit }, data) {
      const model = new CarType()
      return new Promise(((resolve, reject) => {
        axios.put('cars/types/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
              code: response.data.item.code,
              amount: response.data.item.amount,
              ceo: response.data.item.ceo,
              group: response.data.item.group
            }
            await model.editData(item)
            commit('UPDATE_TYPE', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    deleteType({ commit }, id) {
      return new Promise((resolve, reject) => {
        const model = new CarType()
        axios.delete('cars/types/' + id)
          .then(async (response) => {
            await model.delete(id)
            commit('DELETE_TYPE', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async getBodies({ commit }, search) {
      const model = new Body()
      let data
      if (search) {
        data = await model.like({name: search})
      }
      else {
        data = await model.getAll()
      }
      data.sort(function(a, b){
        return a.name.length - b.name.length;
      });
      data.sort(sort.dynamicSort("name"));
      data.sort(sort.dynamicSort("-primary"));
      commit('SET_BODIES', data)
    },

    addBody({ commit }, data) {
      return new Promise(((resolve, reject) => {
        const model = new Body()
        axios.post('cars/bodies', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              primary: response.data.item.primary
            }
            await model.addData(item)
            commit('ADD_BODY', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    async getNewBodyData ({commit}, data) {
      const model = new Body()
      const item = {
        id: data._id,
        name: data.name,
        primary: data.primary
      }
      await model.addData(item)
      commit('ADD_BODY', item)
    },

    editBody({ commit }, data) {
      const model = new Body()
      return new Promise(((resolve, reject) => {
        axios.put('cars/bodies/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
              primary: response.data.item.primary
            }
            await model.editData(item)
            commit('UPDATE_BODY', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    async editNewBodyData ({commit}, data) {
      const model = new Body()
      let item = {
        id: data._id,
        name: data.name,
      }
      await model.editData(item)
      commit('UPDATE_BODY', item)
    },

    deleteBody({ commit }, id) {
      return new Promise((resolve, reject) => {
        const model = new Body()
        axios.delete('cars/bodies/' + id)
          .then(async (response) => {
            await model.delete(id)
            commit('DELETE_BODY', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async deleteBodyData ({commit}, id) {
      const model = new Body()
      await model.delete(id)
      commit('DELETE_BODY', id)
    },

    async getColors({ commit }, search) {
      let data
      if (search) {
        const model = new Color()
        data = await model.like({name: search})
      }
      else {
        const model = new Color()
        data = await model.getAll()
      }

      data.sort(function(a, b){
        return a.name.length - b.name.length;
      });

      data.sort(function(a, b){
        return a.name.toLocaleLowerCase().includes('սև')
      });

      data.sort(sort.dynamicSort("-primary"));
      commit('SET_COLORS', data)
    },

    addColor({ commit }, data) {
      return new Promise(((resolve, reject) => {
        const model = new Color()
        axios.post('cars/colors', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              primary: response.data.item.primary,
            }
            await model.addData(item)
            commit('ADD_COLOR', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    async getNewColorData ({commit}, data) {
      const model = new Color()
      const item = {
        id: data._id,
        name: data.name,
        primary: data.primary,
      }
      await model.addData(item)
      commit('ADD_COLOR', item)
    },

    editColor({ commit }, data) {
      const model = new Color()
      return new Promise(((resolve, reject) => {
        axios.put('cars/colors/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
              primary: response.data.item.primary,
            }
            await model.editData(item)
            commit('UPDATE_COLOR', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    async editNewColorData ({commit}, data) {
      const model = new Color()
      let item = {
        id: data._id,
        name: data.name,
      }
      await model.editData(item)
      commit('UPDATE_COLOR', item)
    },

    deleteColor({ commit }, id) {
      return new Promise((resolve, reject) => {
        const model = new Color()
        axios.delete('cars/colors/' + id)
          .then(async (response) => {
            await model.delete(id)
            commit('DELETE_COLOR', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async deleteNewColorData ({commit}, id) {
      const model = new Color()
      await model.delete(id)
      commit('DELETE_COLOR', id)
    },

    async getFuels({ commit }) {
      const model = new Fuel()
      const data = await model.getAll()
      data.sort(sort.dynamicSort("ordering"));
      commit('SET_FUELS', data)
    },

    addFuel({ commit }, data) {
      return new Promise(((resolve, reject) => {
        const model = new Fuel()
        axios.post('cars/fuels', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              code: response.data.item.code,
              ordering: response.data.item.ordering
            }
            await model.addData(item)
            commit('ADD_FUEL', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    editFuel({ commit }, data) {
      const model = new Fuel()
      return new Promise(((resolve, reject) => {
        axios.put('cars/fuels/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
              code: response.data.item.code,
              ordering: response.data.item.ordering
            }
            await model.editData(item)
            commit('UPDATE_FUEL', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    deleteFuel({ commit }, id) {
      return new Promise((resolve, reject) => {
        const model = new Fuel()
        axios.delete('cars/fuels/' + id)
          .then(async (response) => {
            await model.delete(id)
            commit('DELETE_FUEL', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async getClassificators({ commit }) {
      const model = new Classificator()
      const data = await model.getAll()
      data.sort(sort.dynamicSort("code"));
      commit('SET_CLASSIFICATORS', data)
    },

    async getNewClassificators({ commit }) {
      const model = new NewClassificator()
      const data = await model.getAll()
      data.sort(sort.dynamicSort("code"));
      commit('SET_NEW_CLASSIFICATORS', data)
    },

    addClassificator({ commit }, data) {
      return new Promise(((resolve, reject) => {
        const model = new Classificator()
        axios.post('cars/classificators', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              code: response.data.item.code,
              status: response.data.item.status,
              checked: response.data.item.checked,
            }
            await model.addData(item)
            commit('ADD_CLASSIFICATOR', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    addNewClassificator({ commit }, data) {
      return new Promise(((resolve, reject) => {
        const model = new NewClassificator()
        axios.post('cars/new-classificators', data)
          .then(async (response) => {
            const item = {
              id: response.data.item._id,
              name: response.data.item.name,
              code: response.data.item.code,
              status: response.data.item.status,
              checked: response.data.item.checked,
            }
            await model.addData(item)
            commit('ADD_NEW_CLASSIFICATOR', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    async getNewClassificator({ commit }, data) {
      const model = new Classificator()
      const item = {
        id: data._id,
        name: data.name,
        code: data.code,
        status: data.status,
        checked: data.checked,
      }
      await model.addData(item)
      commit('ADD_CLASSIFICATOR', item)
    },

    editClassificator({ commit }, data) {
      const model = new Classificator()
      return new Promise(((resolve, reject) => {
        axios.put('cars/classificators/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
              code: response.data.item.code,
              status: response.data.item.status,
              checked: response.data.item.checked,
            }
            await model.editData(item)
            commit('UPDATE_CLASSIFICATOR', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    editNewClassificator({ commit }, data) {
      console.log(data)
      const model = new NewClassificator()
      return new Promise(((resolve, reject) => {
        axios.put('cars/new-classificators/' + data.id, data)
          .then(async (response) => {
            let item = {
              id: response.data.item._id,
              name: response.data.item.name,
              code: response.data.item.code,
              status: response.data.item.status,
              checked: response.data.item.checked,
            }
            await model.editData(item)
            commit('UPDATE_NEW_CLASSIFICATOR', item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    async updateClassificatorData ({commit}, data) {
      const model = new Classificator()
      let item = {
        id: data._id,
        name: data.name,
        code: data.code,
        status: data.status,
        checked: data.checked,
      }
      await model.editData(item)
      commit('UPDATE_CLASSIFICATOR', item)
    },

    deleteClassificator({ commit }, id) {
      return new Promise((resolve, reject) => {
        const model = new Classificator()
        axios.delete('cars/classificators/' + id)
          .then(async (response) => {
            await model.delete(id)
            commit('DELETE_CLASSIFICATOR', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteNewClassificator({ commit }, id) {
      return new Promise((resolve, reject) => {
        const model = new NewClassificator()
        axios.delete('cars/new-classificators/' + id)
          .then(async (response) => {
            await model.delete(id)
            commit('DELETE_NEW_CLASSIFICATOR', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },


  },
}
