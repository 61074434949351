export default [
  {
    path: '/settings/cars',
    name: 'settings-cars',
    component: () => import('@/views/pages/cars/Cars'),
    meta: {
      auth: true,
      pageTitle: 'Մակնիշներ',
    },
  },
  {
    path: '/settings/car-models',
    name: 'settings-car-models',
    component: () => import('@/views/pages/cars/CarModels'),
    meta: {
      auth: true,
      pageTitle: 'Մոդելներ',
    },
  },
  {
    path: '/settings/car-groups',
    name: 'settings-car-groups',
    component: () => import('@/views/pages/cars/CarGroups'),
    meta: {
      auth: true,
      pageTitle: 'Խմբեր',
    },
  },
  {
    path: '/settings/car-types',
    name: 'settings-car-types',
    component: () => import('@/views/pages/cars/CarTypes'),
    meta: {
      auth: true,
      pageTitle: 'Տիպեր',
    },
  },
  {
    path: '/settings/bodies',
    name: 'settings-bodies',
    component: () => import('@/views/pages/cars/Body'),
    meta: {
      auth: true,
      pageTitle: 'Թափքի տեսակներ',
    },
  },
  {
    path: '/settings/colors',
    name: 'settings-colors',
    component: () => import('@/views/pages/cars/Color'),
    meta: {
      auth: true,
      pageTitle: 'Գույներ',
    },
  },
  {
    path: '/settings/fuels',
    name: 'settings-fuels',
    component: () => import('@/views/pages/cars/Fuel'),
    meta: {
      auth: true,
      pageTitle: 'Վառելիքի տեսակներ',
    },
  },
  {
    path: '/settings/classificators',
    name: 'settings-classificators',
    component: () => import('@/views/pages/cars/Classificators'),
    meta: {
      auth: true,
      pageTitle: 'Արատորոշումներ',
    },
  },
  {
    path: '/settings/new-classificators',
    name: 'settings-new-classificators',
    component: () => import('@/views/pages/cars/NewClassificators'),
    meta: {
      auth: true,
      pageTitle: 'Արատորոշումներ',
    },
  },
]
