import Model from '@/store/Models/Model'

class Region extends Model {

  constructor() {
    super('regions')
  }

}

export default Region
