import Offline from '@/store/Models/Offline'

class LocalStreet extends Offline {

  constructor() {
    super('streets')
  }

}

export default LocalStreet
